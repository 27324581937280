import React from 'react';
import ContactConfirmationSection from '../components/sections/ContactConfirmationSection';
// import sections

const ContactConfirmation = () => {
  return (
    <>
      <ContactConfirmationSection />
    </>
  );
}

export default ContactConfirmation;