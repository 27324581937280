import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import { useHistory, useLocation } from "react-router-dom";

import emailjs from '@emailjs/browser';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Form = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const [customerName, setCustomerName] = useState('');
  const [customerCell, setCustomerCell] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);


  const history = useHistory();
  const location = useLocation();

  const form = useRef();

  useEffect(()=>{
    setCustomerCell(location.search.slice(7))
  },[location])

  const outerClasses = classNames(
    'form section center-content-mobile center-content-desktop reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'form-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'form-split'
  );  

  const sectionHeader = {
    title: 'Preencha o formulário',
    paragraph: 'E entraremos em contato'
  };

  const sendEmail = (e) => {
    e.preventDefault()

    if(customerName.length !== 0 && customerCell.length >=8 && customerEmail !== 0){
      setIsProcessing(true)
      emailjs.sendForm('service_rlins_website', 'template_btgsy28', form.current, 'gbWMj95Qu0YjYRcLq')
        .then((result) => {
            console.log(result)
            history.push("/contact-confirmation")
          }, (error) => {
            console.log(error)
            history.push("/contact-error")
        });
    }
    else{
      window.alert("Preencha todos os campos corretamente!")
    }

  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <SectionHeader data={sectionHeader} className="center-content" data-reveal-delay="200"/>
          <form ref={form} onSubmit={sendEmail} className="form-action">
            <Input 
              id="newsletter"
              type="text"
              name="customer_name"
              onChange={(e)=>setCustomerName(e.target.value)}
              value={customerName}
              label="Seu nome"
              labelHidden
              placeholder="Nome"
              data-reveal-delay="400"/>
            <Input id="newsletter"
              type="tel"
              name="customer_cell"
              onChange={(e)=>setCustomerCell(e.target.value)}
              value={customerCell}
              label="Seu celular"
              labelHidden
              placeholder="Celular"
              data-reveal-delay="400"/>
            <Input id="newsletter"
              type="email"
              name="customer_email"
              onChange={(e)=>setCustomerEmail(e.target.value)}
              value={customerEmail}
              label="Seu melhor email"
              labelHidden
              placeholder="Email"
              data-reveal-delay="400"/>
          
            <div className="reveal-from-bottom" data-reveal-delay="600">
              <ButtonGroup>
                <Button onClick={sendEmail} value="Send" disabled={isProcessing} tag="a" color="primary" wideMobile >
                  Solicitar um orçamento
                  </Button>
              </ButtonGroup>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;