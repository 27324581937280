import React from 'react';
import ContactErrorSection from '../components/sections/ContactErrorSection';
// import sections

const ContactError = () => {

  return (
    <>
      <ContactErrorSection />
    </>
  );
}

export default ContactError;