import React from 'react';
import Form from '../components/sections/Form';
// import sections

const QuoteForm = () => {

  return (
    <>
      <Form />      
    </>
  );
}

export default QuoteForm;